import AdminHeader from "../../components/admin_header/admin_header";
import CheckAuto from "../../components/CheckAuto/CheckAuto";

function CheckAutoAdmin() {

    return (
        <div className="d-flex">
            <AdminHeader />
            <CheckAuto />
        </div>
    );
}

export default CheckAutoAdmin;
