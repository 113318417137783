import React from 'react'
import { useSelector } from 'react-redux'
import '../../components/style.css'
import { Language } from '../../lang/Languages'
import img from "../../assets/aksiya.jpg"

import telegram_i from '../../images/telegram_i.png'
import instagram_i from "../../images/intagram_i.png"
import facebook_i from "../../images/facebook_i.png"
import youtube_i from "../../images/youtube_i.png"

const Aksiya = () => {
    const { lang } = useSelector(state => state.lang)

    const { aksiya_1, aksiya_2, aksiya_3, aksiya_4, aksiya_5, aksiya_6, aksiya_7, f9 } = Language
    return (
        <main>
            <div className='pages-container'>
                <div className='komissia-wrap'>
                    <img className='aksiya-img' src={img} alt='' />
                    <h3>{aksiya_1[lang]}</h3>
                    <ul className='komissia-list'>
                        <li>{aksiya_2[lang]}</li>
                        <li>{aksiya_3[lang]}</li>
                        <li>{aksiya_4[lang]}</li>
                        <li>{aksiya_5[lang]}</li>
                        <li>{aksiya_6[lang]} </li>
                        <br />

                        <li>{aksiya_7[lang]}</li>
                    </ul>
                    <div className="footer__block-aksiya">
                        <h4 className="footer__title">{f9[lang]}</h4>
                        <div className="footer__line"></div>
                        <div className='social_icon_container'>
                            <a href="https://t.me/R_Auto_uz"><img className='social_icon' src={telegram_i} alt="telegram" /></a>
                            <a href="https://www.instagram.com/rauto.uz/"><img className='social_icon' src={instagram_i} alt="instagram" /></a>
                            <a href=" https://www.facebook.com/RetailAutoCar"><img className='social_icon' src={facebook_i} alt="facebook" /></a>
                            <a href="https://www.youtube.com"><img className='social_icon social_icon_yt' src={youtube_i} alt="youtube" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </main>

    )
}

export default Aksiya