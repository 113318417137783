import React from 'react'
import { useSelector } from 'react-redux'
import director from "../../images/director.png"
import team0 from "../../assets/img/p.jpg"
import team1 from "../../images/team1.png"
import team2 from "../../images/team2.png"
import team3 from "../../images/team3.png"
import team5 from "../../images/team5.png"
import team6 from "../../images/team6.jpg"
import team7 from "../../images/team7.jpg"
import team8 from "../../images/team8.jpg"
import team9 from "../../images/team9.jpg"
import team10 from "../../images/team10.jpg"
import team11 from "../../images/team11.jpg"
import { Language } from '../../lang/Languages'

function Ourteam() {
    const { lang } = useSelector(state => state.lang)

    const { a9, tm1, tm2, tm3, tm4, tm5, tm6, tm7, tm8, tm9, tm10, tm11, tm12, tm13, tm14, tm15, tm16, tm17, tm18, } = Language

    return (
        <main>
            <div className='social__title'>
                <span></span>
                <h2>{a9[lang]}</h2>
                <span></span>
            </div>
            <div className='container mt-2'>
                {/* row--111111 */}
                <div className='row'>
                    <div className='col-lg-3'>
                        <div className='card card_team'>
                            <img src={director} className="team-img-two" alt='img7' />
                            <div className='card-body text-center'>
                                <ul>
                                    <li>{tm13[lang]}</li>
                                    <li className='team_list'><span class="material-symbols-outlined">
                                        work
                                    </span>{tm14[lang]}</li>
                                    <p><span class="material-symbols-outlined">
                                        phone
                                    </span>+998 90 915 54 94</p>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className='card card_team'>
                            <img src={team0} className="team-img-two" alt='img0' />
                            <div className='card-body text-center'>
                                <ul>
                                    <li>{tm1[lang]}</li>
                                    <li className='team_list'><span className="material-symbols-outlined">
                                        work
                                    </span>{tm15[lang]}</li>
                                    <li className='team_list'>
                                        <span className="material-symbols-outlined">
                                            phone
                                        </span>
                                        +998 90 770 10 20
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className='card card_team'>
                            <img src={team10} className="team-img-two" alt='img10' />
                            <div className='card-body text-center'>
                                <ul>
                                    <li>{tm11[lang]}</li>
                                    <li className='team_list'><span class="material-symbols-outlined">
                                        work
                                    </span>{tm12[lang]}</li>
                                    <li className='team_list'><span class="material-symbols-outlined">
                                        phone
                                    </span>
                                        +998 97 000 85 85
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className='card card_team'>
                            <img src={team11} className="team-img-two" alt='img11' />
                            <div className='card-body text-center'>
                                <ul>
                                    <li>{tm9[lang]}</li>
                                    <li className='team_list'><span class="material-symbols-outlined">
                                        work
                                    </span>{tm10[lang]}</li>
                                    <li className='team_list'><span class="material-symbols-outlined">
                                        phone
                                    </span>
                                        +998 97 650 10 00
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* row--222222 */}
                <div className="row">
                    <div className='col-lg-3'>
                        <div className='card card_team'>
                            <img src={team6} className="team-img-two" alt='img3' />
                            <div className='card-body text-center'>
                                <ul>
                                    <li>{tm6[lang]}</li>
                                    <li className='team_list'><span class="material-symbols-outlined">
                                        work
                                    </span>{tm7[lang]}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className='card card_team'>
                            <img src={team3} className="team-img-two" alt='img4' />
                            <div className='card-body text-center'>
                                <ul>
                                    <li>{tm8[lang]}</li>
                                    <li className='team_list'><span class="material-symbols-outlined">
                                        work
                                    </span>{tm7[lang]}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className='card card_team'>
                            <img src={team1} className="team-img-two" alt='img2' />
                            <div className='card-body text-center'>
                                <ul>
                                    <li>{tm5[lang]}</li>
                                    <li className='team_list'><span class="material-symbols-outlined">
                                        work
                                    </span>{tm2[lang]}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className='card card_team'>
                            <img src={team7} className="team-img-two" alt='img6' />
                            <div className='card-body text-center'>
                                <ul>
                                    <li>{tm16[lang]}</li>
                                    <li className='team_list'><span class="material-symbols-outlined">
                                        work
                                    </span>{tm2[lang]}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* row--333333 */}
                <div className="row">
                    <div className='col-lg-3'>
                        <div className='card card_team'>
                            <img src={team5} className="team-img-two" alt='img5' />
                            <div className='card-body text-center'>
                                <ul>
                                    <li>{tm3[lang]}</li>
                                    <li className='team_list'><span class="material-symbols-outlined">
                                        work
                                    </span>{tm2[lang]}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className='card card_team'>
                            <img src={team2} className="team-img-two" alt='img1' />
                            <div className='card-body text-center'>
                                <ul>
                                    <li>{tm4[lang]}</li>
                                    <li className='team_list'><span class="material-symbols-outlined">
                                        work
                                    </span>{tm2[lang]}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className='card card_team'>
                            <img src={team9} className="team-img-two" alt='img7' />
                            <div className='card-body text-center'>
                                <ul>
                                    <li>{tm17[lang]}</li>
                                    <li className='team_list'><span class="material-symbols-outlined">
                                        work
                                    </span>{tm2[lang]}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className='card card_team'>
                            <img src={team8} className="team-img-two" alt='img7' />
                            <div className='card-body text-center'>
                                <ul>
                                    <li>{tm18[lang]}</li>
                                    <li className='team_list'><span class="material-symbols-outlined">
                                        work
                                    </span>{tm2[lang]}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Ourteam
